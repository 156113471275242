<template>
  <commonHeader></commonHeader>
  <div class="wrap">
    <div class="w950 mauto ohidden">
      <h1>联系我们</h1>
      <div><img width="137" src="~@/assets/images/contact-qrcode1.png" alt /></div>
      <p class="pb20 pt10">地址：北京市海淀区学院路街道学院8号写字楼A座6层607</p>
      <div class="line pb30 mt5"></div>
      <h1>广告服务</h1>
      <p class="pb15">笔记侠媒介公关：魏智上</p>
      <p class="pb20">手机号：17631881957</p>
      <div><img width="140" src="~@/assets/images/contact-qrcode2.png" alt /></div>

      
    </div>
  </div>
  <commonFooter></commonFooter>
</template>

<script>
import commonHeader from '../public/header.vue'
import commonFooter from '../public/footer.vue'
export default {
  components: {
    commonHeader,
    commonFooter,
  },
  data() {
    return {}
  },
}
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  padding: 38px 0 62px;
  background: #f9f9f9;
  .w950 {
    h1 {
      font-size: 20px;
      color: #111;
      font-weight: bold;
      padding-bottom:28px;
    }
    h2 {
      font-size: 18px;
      color: #111;
      font-weight: bold;
      line-height: 37px;
    }
    p {
      font-weight: bold;
      line-height: 20px;
      font-size:16px;
    }
  }
}
</style>